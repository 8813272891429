<template>
  <div class="main">
    <h1>{{ gameDetails.teamName }}</h1>
    <div
      :style="{
        width: '100%',
        margin: '16px 0 0 0',
        display: 'flex',
        'flex-direction': 'column',
      }"
    >
      <h2 class="display-1"><span></span>ЖДИ</h2>
      <h2 class="display-2"><span></span>ЖМИ</h2>
      <h2 class="display-3"><span></span>ОТВЕЧАЙ</h2>
    </div>
    <div class="game">
      <button
        @click="tap"
        v-bind:class="{
          pressed: isPressed,
          green: showSuccess,
          grey: !gameStarted || wait,
        }"
      >
        <!-- <span>{{
          !gameStarted || wait
            ? waitText
            : showSuccess
            ? successText
            : commonText
        }}</span> -->
      </button>
    </div>
  </div>
</template>

<script>
import * as Colyseus from "colyseus.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Game",
  data: () => {
    return {
      gameStarted: false,
      wait: false,
      isPressed: false,
      canPress: false,
      showSuccess: false,
      commonText: "ЖМИ",
      successText: "ОТВЕЧАЙ",
      waitText: "ЖДИ",
      state: null,
    };
  },
  computed: {
    ...mapGetters({
      gameDetails: "gameDetails",
    }),
  },
  components: {},
  methods: {
    tap() {
      if (this.$data.canPress) {
        this.$data.isPressed = true;

        if (this.$data.room) {
          this.$data.room.send("tap", {});
        }

        setTimeout(() => {
          this.$data.isPressed = false;
        }, 500);
      }
    },
  },
  mounted: async function () {
    this.$data.sessionId = "";
    let gameId = this.gameDetails.gameId;
    let teamName = this.gameDetails.teamName;
    console.log(this.gameDetails);

    if (!teamName) {
      let oldGameId = localStorage.getItem("gameId");
      let oldTeamName = localStorage.getItem("teamName");
      this.$data.sessionId = localStorage.getItem("sessionId");
      if (oldGameId && oldTeamName)
        this.$store.dispatch("saveGameDetails", {
          gameId: oldGameId,
          teamName: oldTeamName,
        });
    }

    // gameId = this.gameDetails.gameId;
    teamName = this.gameDetails.teamName;

    if (!teamName) {
      this.$router.push("/");
    } else {
      // localStorage.setItem("gameId", gameId);
      localStorage.setItem("teamName", teamName);
      localStorage.setItem("sessionId", "");

      let sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };
      let connect = async (reconnect = false) => {
        let client = new Colyseus.Client("wss://smartcity.ugscompany.ru/api");
        let room;
        if (!reconnect)
          room = await client.joinOrCreate("smart_city_room", { teamName });
        else room = await client.reconnect("7777", this.$data.sessionId);
        console.log(room);
        if (!room) this.$router.push("/");

        this.$data.room = room;
        this.$data.sessionId = room.sessionId;

        gameId = room.id;

        localStorage.setItem("gameId", gameId);
        localStorage.setItem("sessionId", room.sessionId);

        this.$store.dispatch("saveGameDetails", {
          gameId: gameId,
          teamName: teamName,
        });

        room.onStateChange((state) => {
          console.log("the room state has been updated:", state);

          this.$data.gameStarted = state.gameStarted;

          let teamData = state.teams.get(room.sessionId);
          if (teamData) {
            if (teamData.canPress && state.gameStarted) {
              this.$data.wait = false;
              this.$data.canPress = true;
              // this.$data.isPressed = false;
            } else {
              this.$data.wait = true;
              this.$data.canPress = false;
              // this.$data.isPressed = false;
            }
          }

          let iAmIndex = state.pressQueue.indexOf(room.sessionId);
          if (iAmIndex == -1) {
            this.$data.showSuccess = false;
          } else if (iAmIndex > 0) {
            this.$data.wait = true;
            this.$data.showSuccess = false;
            this.$data.canPress = false;
          } else {
            this.$data.showSuccess = true;
            this.$data.canPress = false;
          }
          // this.$data.state = state;
        });

        room.onLeave(async (code) => {
          console.log("client left the room", code);
          if (code > 1000 && code < 1016) {
            let success = false;
            while (!success) {
              try {
                await connect(true);
                success = true;
                console.log("join success");
              } catch (e) {
                console.log("join error", e);
                success = false;
                await sleep(1000);
              }
            }
          }
        });

        room.onError((code, message) => {
          console.log("oops, error ocurred:", code);
          console.log(message);
        });
      };

      try {
        if (this.$data.sessionId) connect(true);
        else connect();
      } catch (error) {
        console.log(error);
        this.$router.push("/");
      }
    }
  },
};
</script>

<style scoped>
.main {
  display: flex;
  height: 100%;
  width: 100%;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.game {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
}

h1 {
  border: none;
  color: white;
  /* font-family: Avenir, Arial, sans-serif;
  font-weight: 900; */
  font-size: 3em;
  text-shadow: 0 4px 3px rgb(0 0 0);
  outline: none;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0.4em;
  line-height: 2rem;
}

@media (max-width: 400px) {
  h1 {
    font-size: 2em;
  }
}

button {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  border: none;
  color: white;
  /* font-family: Avenir, Arial, sans-serif;
  font-weight: 900; */
  font-size: 4.5rem;
  /* background: radial-gradient(#ff0000, #630000); */
  background: url("/2.png");
  background-size: contain;
  /* background: red; */
  text-shadow: 0 4px 3px rgb(0 0 0);
  /* box-shadow: 0 16px 0 rgb(183, 9, 0), 0 25px 30px rgba(0, 0, 0, 0.35); */
  text-transform: uppercase;
  /* transition: 0.3s all ease-in; */
  outline: none;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button span {
  position: relative;
}

button.green {
  /* background: rgb(1, 131, 1); */
  /* background: radial-gradient(#ffe390, #865d1b); */
  background: url("/3.png");
  background-size: contain;
  /* text-shadow: 0 3px 1px rgba(0, 104, 3, 0.8); */
  /* box-shadow: 0 16px 0 rgb(0, 104, 3), 0 25px 30px rgba(0, 0, 0, 0.35); */
  font-size: 3.5rem;
}

button.grey {
  /* background: rgb(118, 118, 118); */
  /* background: radial-gradient(#a2a2a2, #3e3e3e); */
  background: url("/1.png");
  background-size: contain;
  /* text-shadow: 0 3px 1px rgba(75, 75, 75, 0.8); */
  /* box-shadow: 0 16px 0 rgb(56, 56, 56), 0 25px 30px rgba(0, 0, 0, 0.35); */
  font-size: 4.5rem;
}

h2 {
  border: none;
  color: white;
  /* font-family: Avenir, Arial, sans-serif;
  font-weight: 900; */
  font-size: 1em;
  text-shadow: 0 4px 3px rgb(0 0 0);
  outline: none;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  align-items: center;
  padding: 4px 16px;
}

.display-1 span,
.display-2 span,
.display-3 span {
  width: 32px;
  height: 32px;
  display: block;
  margin-right: 8px;
}

.display-1 span {
  background: url(/1.png);
  background-size: contain;
}

.display-2 span {
  background: url(/2.png);
  background-size: contain;
}

.display-3 span {
  background: url(/3.png);
  background-size: contain;
}
/* fix for IE 11 (and IE8+, although the earlier versions are harder to address) stupidly moving the inner button text on click */
/* button.green.pressed {
  padding-top: 3px;
  transform: translateY(12px);
  box-shadow: 0 4px 0 rgb(183, 0, 0), 0 8px 6px rgba(0, 0, 0, 0.45);
}
button.grey.pressed {
  padding-top: 3px;
  transform: translateY(12px);
  box-shadow: 0 4px 0 rgb(183, 0, 0), 0 8px 6px rgba(0, 0, 0, 0.45);
}
button.pressed {
  padding-top: 3px;
  transform: translateY(12px);
  box-shadow: 0 4px 0 rgb(183, 0, 0), 0 8px 6px rgba(0, 0, 0, 0.45);
} */
</style>