<template>
  <div class="main">
    <img src="/corner.png" />
    <h1 v-for="(teamName,index) in firstTeamNames" v-bind:key="teamName">
      {{ `${index+1}. ${teamName}` }}
    </h1>
    <h2 v-for="(teamName,index) in secondTeamNames" v-bind:key="teamName">
      {{ `${index+4}. ${teamName}` }}
    </h2>
  </div>
</template>

<script>
import * as Colyseus from "colyseus.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "View",
  data: () => {
    return {
      gameId: "7777",
      firstTeamNames: [],
      secondTeamNames: [],
    };
  },
  computed: {},
  components: {},
  methods: {},
  mounted: async function () {
    let client = new Colyseus.Client("wss://smartcity.ugscompany.ru/api");
    // this.$data.gameId = this.$route.params.gameid;
    let room = await client.joinById(this.$data.gameId, { view: true });

    room.onMessage("onTap", (message) => {
      console.log("onTap", message);
      //   if (message.num == 0) this.$data.teamName = message.name;
      if (this.$data.firstTeamNames.length < 3)
        this.$data.firstTeamNames.push(message.name);
      else this.$data.secondTeamNames.push(message.name);
    });

    room.onMessage("onClean", (message) => {
      console.log("onClean", message);
      this.$data.firstTeamNames = [];
      this.$data.secondTeamNames = [];
    });

    room.onLeave((code) => {
      console.log("client left the room", code);
    });

    room.onError((code, message) => {
      console.log("oops, error ocurred:", code);
      console.log(message);
    });
  },
};
</script>

<style scoped>
.main {
  display: flex;
  height: 100%;
  width: 100%;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border-image: url('/corner.png') 30; */
}

.main h1,
.main h2,
.main table {
  border: none;
  color: white;
  /* font-family: Avenir, Arial, sans-serif; */
  font-weight: 900;
  /* text-shadow: 0 3px 1px rgba(122, 17, 8, 0.8); */
  outline: none;
  text-align: center;
}

h1 {
  font-size: 5em;
  margin-bottom: 0;
  margin-top: 0em;
  background: linear-gradient(90rad, #ffe996, #bb8d40, #ffe996);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 0.9em;
}

h2 {
  line-height: 0.9em;
  font-size: 3em;
  margin-bottom: 0;
  margin-top: 0em;
  background: linear-gradient(90rad, #ffe996, #bb8d40, #ffe996);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

img {
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
  position: absolute;
}
</style>