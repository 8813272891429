<template>
  <div class="home">
    <CreateForm
      createText="Создать"
      reconnectText="Вернуться"
      gameIdPlaceholder="ID игры..."
      v-on:create="createGame($event)"
      v-on:reconnect="reconnectGame($event)"
    />
  </div>
</template>

<script>
import CreateForm from "@/components/CreateForm.vue";

export default {
  name: "Create",
  components: {
    CreateForm,
  },
  methods: {
    createGame() {
      console.log("createGame");
      //   this.$store.dispatch("saveGameDetails", { gameId, teamName });
      this.$router.push("/control");
    },
    reconnectGame({ gameId }) {
      console.log("reconnectGame", { gameId });
      this.$store.dispatch("saveReconnectData", { gameId });
      this.$router.push("/control");
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
}
</style>