<template>
  <div class="form">
    <!-- <input v-model="gameId" v-bind:placeholder="gameIdPlaceholder" />
    <input v-model="teamName" v-bind:placeholder="teamNamePlaceholder" /> -->
    <input @click="create" type="button" v-bind:value="createText" />
    <!-- <div>ИЛИ</div>
    <input v-model="gameId" v-bind:placeholder="gameIdPlaceholder" />
    <input @click="reconnect" type="button" v-bind:value="reconnectText" /> -->
  </div>
</template>

<script>
export default {
  name: "CreateForm",
  data: () => {
    return {
      gameId: "",
    //   teamName: "",
    };
  },
  props: {
    gameIdPlaceholder: String,
    // teamNamePlaceholder: String,
    createText: String,
    reconnectText: String,
  },
  emits: ["create", "reconnect"],
  methods: {
    create() {
      this.$emit("create", {
        // gameId: this.$data.gameId,
        // teamName: this.$data.teamName,
      });
    },
    reconnect() {
      this.$emit("reconnect", {
        gameId: this.$data.gameId,
        // teamName: this.$data.teamName,
      });
    },
  },
};
</script>

<style scoped>
.form {
  display: grid;
}
</style>
